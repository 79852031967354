.visible-desktop {}
.visible-mobile {
    display: none!important;
}

@media (max-width: 1199px) {
    .visible-desktop {
        display: none!important;
    }
    .visible-mobile {
        display: inherit!important;
    }
}
